.rb-button {
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 6px;
    
    padding: 0.09rem;
    overflow: hidden;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    transition: 150ms ease all;
    background-size: 100% 100%;
    
    &__glass::before {
        position: absolute;
        content: ' ';
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;

        filter: blur(3px);
        filter: url('#blur');
    }

    &__loading {
        position: absolute;
        bottom: 30%;
        top: 30%;
        right: 0;
        left: 0;

        -webkit-animation:spin 2s linear infinite;
        -moz-animation:spin 2s linear infinite;
        animation:spin 2s linear infinite;
    }
    
    &__content-wrapper {
        z-index: 5;
        width: 100%;
        border-radius: 6px;
    }
    
    &--regular, &--icon {
        &:hover {
            background-size: 200% 100%;
        }
    }

    &--regular &__content-wrapper {
        padding: 4px 8px;
        font-family: JetBrains, sans-serif;
    }

    &--icon {
        width: 18px;
        height: 18px;
        padding: 0.1rem;
        border-radius: 50%;
    }

    &--large {
        width: 24px;
        height: 24px;
    }

    &--icon &__content-wrapper {
        width: 100%;
        height: 100%;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--primary {
        &--on {
            &:hover {
                background-size: 100% 100%;
            }
        }
    }

    &--accent {
        &--on {
            &:hover {
                background-size: 100% 100%;
            }
        }
    }
}

.rb-button:disabled {
    opacity: 0.5;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@mixin button-theme($theme) {
    .rb-button {
        background: map-get($theme, button-default-background);

        &--regular .rb-button__content-wrapper {
            color: map-get($theme, button-text);
            background: map-get($theme, button-content-wrapper-background);
        }

        &--icon .rb-button__content-wrapper {
            background: map-get($theme, button-content-wrapper-background);

            &:hover {
                background: map-get($theme, button-content-wrapper-hover-background);
            }
        }

        &__loading {
            background: map-get($theme, button-loading-accent-background);
            
            &--primary {
                background: map-get($theme, button-loading-primary-background);
            }
        
            &--accent {
                background: map-get($theme, button-loading-accent-background);
            }
        }

        &--primary {
            &--on {
                background: map-get($theme, button-loading-primary-background);
            }
        }

        &--accent {
            &--on {
                background: map-get($theme, button-loading-accent-background);;
            }
        }

        &--on &__content-wrapper {
            background: map-get($theme, button-on-content-wrapper-background);
            box-shadow: map-get($theme, button-on-content-wrapper-shadow);
        }
    }
}