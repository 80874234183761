.rb-text-input {
    border: 0;
    padding: 8px 8px;
    border-radius: 4px;

    &:disabled {
        opacity: 0.7;
    }
}

@mixin text-input-theme($theme) {
    .rb-text-input {
        color: rgba(255, 255, 255, 0.4);
        background-color: rgb(56, 56, 56);
    }
}