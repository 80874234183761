.rb-box-header {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left, &__right {
        display: flex;
        align-items: center;
    }

    &__right .rb-placeholder {
        margin-left: 5px;
    }

    &__select {
        .rb-button__content-wrapper {
            text-align: left;
            min-width: 65px;
            font-size: 0.55em;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
    
    &__title {
        margin-left: 8px;
        font-size: 0.8em;
        text-transform: uppercase;

        display: flex;
        align-items: center;

        .rb-icon {
            margin-right: 6px;
        }
    }
}

@mixin box-header-theme($theme) {
    .rb-box-header {
        &--expanded {
            border-bottom: 0.1em solid map-get($theme, border);
        }
    }
}