.rb-placeholder {
    padding: 0.1rem;
    display: inline-block;

    &--icon {
        width: 28px;
        height: 28px;
        border-radius: 50%;
    }

    &--large {
        width: 35px;
        height: 35px;
    }

    &--icon &__content {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--regular {
        border-radius: 6px;
    }

    &--regular &__content {
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__content {
        width: 100%;
        height: 100%;
        padding: 3px;
    }
}

@mixin placeholder-theme($theme) {
    .rb-placeholder {
        background: map-get($theme, placeholder-background);
        &__content {
            background: map-get($theme, placeholder-content);
        }

        &--primary {
            background: map-get($theme, placeholder-primary);
        }
    
        &--accent {
            background: map-get($theme, placeholder-accent);
        }

        &--primary .rb-placeholder__content, &--accent .rb-placeholder__content {
            background: map-get($theme, placeholder-colored-content);
        }
    }
}