.rb-box {
    padding: 0.07rem;
    border-radius: 6px;
    mix-blend-mode: normal;

    &__content-wrapper {
        padding: 8px;
        border-radius: 6px;
    }

    &__content-wrapper2 {
        padding: 0.1rem;
        border-radius: 6px;
    }

    &__content {
        border-radius: 6px;
    }
}

@mixin box-theme($theme) {
    .rb-box {
        background: rgba(#FFFFFF, .1);

        &__content-wrapper {
            background: map-get($theme, background);
        }

        &__content-wrapper2 {
            background: map-get($theme, border);
        }

        &__content {
            background: map-get($theme, background);
        }

        &--highlight {
            .rb-box__content  {
                background: linear-gradient(90deg, #313131 0%, #202020 100%);
            }
        }
    }  
}