@import './abstracts/variables';
@import './base/typography';

@import '../modules/shared/UIKit/TextInput/TextInput.scss';
@import '../modules/shared/UIKit/Placeholder/Placeholder.scss';
@import '../modules/shared/UIKit/BoxHeader/BoxHeader.scss';
@import '../modules/shared/UIKit/Button/Button.scss';
@import '../modules/shared/UIKit/Box/Box.scss';
@import '../modules/shared/Header/Header.scss';
@import '~react-redux-toastr/src/styles/index';

* {
    box-sizing: border-box;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.redux-toastr {
    font-size: 0.8em;
    font-family: JetBrains, sans-serif;

    .toastr-icon {
        transform: scale(0.9);
    }
}

.animated.fadeIn,
.animated.fadeOut {
    animation-duration: 150ms;
}

.theme-dark {
    min-height: 100vh;
    color: map-get($dark-theme-colors, foreground);
    background: linear-gradient(252.44deg, #141414 0%, #323232 100%);

    .text-primary {
        color: map-get($dark-theme-colors, primary);
    }

    .text-accent {
        color: map-get($dark-theme-colors, accent);
    }

    @include box-theme($dark-theme-colors);
    @include header-theme($dark-theme-colors);
    @include text-input-theme($dark-theme-colors);
    @include placeholder-theme($dark-theme-colors);
    @include box-header-theme($dark-theme-colors);
    @include button-theme($dark-theme-colors);
}

.theme-light {
    min-height: 100vh;
    color: map-get($light-theme-colors, foreground);
    background: linear-gradient(299.64deg, #C3D2E6 -0.02%, #DCE6F5 54.14%, #D2E1F0 100.43%);

    .text-primary {
        color: map-get($light-theme-colors, primary);
    }

    .text-accent {
        color: map-get($light-theme-colors, accent);
    }

    @include box-theme($light-theme-colors);
    @include header-theme($light-theme-colors);
    @include text-input-theme($light-theme-colors);
    @include placeholder-theme($light-theme-colors);
    @include box-header-theme($light-theme-colors);
    @include button-theme($light-theme-colors);
}

.modal-overlay {
    position: fixed;
    inset: 0px;
    z-index: 100;
    background: rgba(0, 0, 0, 0.75) !important;
    .color-picker-modal {
        outline: 0;
        z-index: 500;
        inset: 50% auto auto 50%;
        color: rgba(255, 255, 255, 0.4);
        margin-right: -50%;
        transform: translate(-50%, -50%);
        position: absolute;
        overflow: auto;
        border-radius: 4px;
        outline: none;
    }
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}