@font-face {
  font-family: JetBrains;
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/ttf/JetBrainsMono-Regular.ttf')
    format('truetype');
}
  
@font-face {
    font-family: JetBrains;
    font-style: normal;
    font-weight: bold;
    src: url('../../assets/fonts/ttf/JetBrainsMono-Bold.ttf')
      format('truetype');
}
  
@font-face {
    font-family: JetBrains;
    font-style: normal;
    font-weight: 500;
    src: url('../../assets/fonts/ttf/JetBrainsMono-Medium.ttf')
      format('truetype');
}
  
@font-face {
    font-family: JetBrains;
    font-style: normal;
    font-weight: 300;
    src: url('../../assets/fonts/ttf/JetBrainsMono-Light.ttf')
      format('truetype');
}
  
@font-face {
    font-family: JetBrains;
    font-style: normal;
    font-weight: 200;
    src: url('../../assets/fonts/ttf/JetBrainsMono-ExtraLight.ttf')
      format('truetype');
}
  
@font-face {
    font-family: JetBrains;
    font-style: normal;
    font-weight: normal;
    src: url('../../assets/fonts/ttf/JetBrainsMono-Regular.ttf')
      format('truetype');
}
  
html {
    font-size: 100%;
    touch-action: manipulation;
    height: 100%;
}
  
body {
    margin: 0;
    font-family: JetBrains, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    font-size: 16px;
}
  
body > #root {
    min-height: 100%;
    margin: auto;
    touch-action: manipulation;
}
  
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}
  
input {
    outline: 0;
    min-width: 0;
}
  