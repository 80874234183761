.rb-header {
    padding: 10px;
    padding-right: 16px;
    -webkit-box-shadow: 0px 1px 7px 0px rgba(0,0,0,0.27);
    -moz-box-shadow: 0px 1px 7px 0px rgba(0,0,0,0.27);
    box-shadow: 0px 1px 7px 0px rgba(0,0,0,0.27);
    border-bottom: 0.1em solid transparent;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        display: flex;
        align-items: center;
    }

    &-fix {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
    }
}



@mixin header-theme($theme) {
    .rb-header {
        background: map-get($theme, background);
        border-bottom: 1px solid map-get($theme, border);
    }
}